<template>
  <el-dialog append-to-body :visible.sync="visible" :width="width" @close="handleClose">
    <div class="p-dialog">
      <div class="p-dialog-title">
        <slot name="title"></slot>
      </div>
      <div class="p-dialog-content">
        <slot name="content"></slot>
      </div>
      <div v-if="footer" slot="footer" class="p-dialog-footer">
        <el-button v-if="cancelShow" type="default" @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="disabled">{{ button }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'PDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: true
    },
    cancelShow: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '50%'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    button: {
      type: String,
      default: '确定'
    }
  },
  methods: {
    // 弹窗关闭的回调
    handleClose () {
      this.$emit('close')
      this.$emit('update:visible', false)
    },
    // 点击取消
    handleCancel () {
      this.$emit('cancel')
      this.handleClose()
    },
    // 点击确认
    handleConfirm () {
      this.$emit('confirm')
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog {
  .el-dialog__body {
    padding: 24px;
    color: #333333;
    text-align: left;
  }

  .el-dialog__header {
    display: none;
  }

  .p-dialog-footer {
    text-align: right;
    margin-top: 20px;
  }
}
</style>
