<template>
  <div class="authorized">
    <div class="header-left">
      <div>
        <label>组织名称：</label>
        <el-select
          v-model="newOrgId"
          filterable
          @change="handleTaxPayerSelect"
          :filter-method="handleTaxPayerFilter"
        >
          <el-option
            v-for="item in taxPayerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span style="float: left; color: #666666; padding-right: 15px">{{
              item.name
            }}</span>
            <span style="float: right; color: #3d94ff; font-size: 13px">{{
              item.taxNo
            }}</span>
          </el-option>
        </el-select>
      </div>
      <div>
        <el-tooltip
          v-if="authorizeRtn.isReadied && authorizeRtn.authorizeMode == 'RPA'"
          effect="light"
          :content="authorizeRtn.usernameForRpa"
          placement="top"
        >
          <img src="@/assets/icon/deduction/success.png" alt="授权成功" />
        </el-tooltip>
        <img
          v-if="authorizeRtn.isReadied && authorizeRtn.authorizeMode != 'RPA'"
          src="@/assets/icon/deduction/success.png"
          alt="授权成功"
        />
        <img
          v-if="!authorizeRtn.isReadied"
          src="@/assets/icon/deduction/faild.png"
          alt="授权失败"
        />
        <span @click="handleReAuthorize">重新授权</span>
        <span
          style="font-size: 12px"
          v-if="authorizeRtn.isReadied && authorizeRtn.authorizeMode == 'RPA'"
        ></span>
      </div>
    </div>
    <div class="header-right">
      <span>当前所属税期：</span>
      <span class="header-period">{{ fmtTaxPeriod(authorizeRtn.dqssq) }}</span>
      <span
        >(&nbsp;勾选截止：{{ fmtDeadline(authorizeRtn.sbjzrq) }}&nbsp;)</span
      >
    </div>
    <income-info-dialog
      :visible.sync="loginSkDialogVisible"
      :equipment="equipment"
      @confirm="handleAuthorized(true)"
    ></income-info-dialog>

    <AuthorizationLogin
      :visible.sync="loginRxDialogVisible"
      :userList="userList"
      :sendSuccess="sendSuccess"
      :equipment="equipment"
      @getVerificationCode="getVerificationCode"
      @confirmRpa="handleAuthorized"
      @verificationCodeNotarize="verificationCodeNotarize"
    ></AuthorizationLogin>
  </div>
</template>

<script>
import IncomeInfoDialog from "./IncomeInfoDialog";
import AuthorizationLogin from "./AuthorizationLogin.vue";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import {
  reqToken,
  reqState,
  clearCacheState,
  getDzsjUser,
  getSmsCode,
} from "@/service/deduction";

export default {
  name: "IncomeAuthorized",
  components: { OrganizationSelect, IncomeInfoDialog, AuthorizationLogin },
  data() {
    return {
      loginSkDialogVisible: false,
      //   oldOrgId: undefined, // 上一次选择的组织
      newOrgId: undefined,
      authorizeRtn: {
        id: undefined,
        orgId: undefined,
        taxNo: undefined,
        name: undefined,
        isReadied: false,
        authorizeMode: undefined,
      },
      equipment: {},
      loginRxDialogVisible: false,
      formLabelWidth: "120px",
      userList: [],
      sendSuccess: "1",
      allTaxPayerList: [],
      taxPayerList: [],
    };
  },
  created() {
    this.allTaxPayerList = this.$store.state.user.organizations.filter(
      (item) => item.isTaxpayer == "Y"
    );
    this.taxPayerList = JSON.parse(JSON.stringify(this.allTaxPayerList));

    this.selectDefaultTaxPayer();
    this.handleAuthorized();
  },
  watch: {
    loginRxDialogVisible: {
      handler(val) {
        if (val) {
          this.requestGetRpaUser();
        }
      },
    },
    // newOrgId: {
    //   handler() {
    //     console.log("newOrgId changed...", JSON.stringify(this.authorizeRtn));
    //     let idx = this.allTaxPayerList.indexOf(
    //       (item) => item.id == this.newOrgId
    //     );
    //     if (idx != -1) {
    //       this.authorizeRtn = this.allTaxPayerList[idx];
    //       return;
    //     }
    //     this.authorizeRtn = {
    //       orgId: undefined,
    //       taxNo: undefined,
    //       name: undefined,
    //     };
    //   },
    // },
  },
  methods: {
    // 选择纳税主体过滤方法
    handleTaxPayerFilter(val) {
      val = val.toUpperCase();
      this.taxPayerList = this.allTaxPayerList.filter(
        (item) => item.name.indexOf(val) != -1 || item.taxNo.indexOf(val) != -1
      );
    },
    async requestGetRpaUser() {
      let res = await getDzsjUser({
        orgId: this.equipment.orgId,
      });
      if (res.success) {
        this.userList = res.data.records;
      }
    },
    // 选中用户默认纳税主体
    async selectDefaultTaxPayer() {
      if (!this.allTaxPayerList || this.allTaxPayerList.length < 1) {
        return;
      }
      // 已经点选组织 -> 所属组织 -> 组织列表第一个（没有所属组织 ｜｜ 没有所属组织的数据权限）
      let orgId = localStorage.getItem("deductOrgId")
        ? localStorage.getItem("deductOrgId")
        : localStorage.getItem("orgId");
      if (
        !orgId ||
        this.allTaxPayerList.find((item) => item.id == orgId) == -1
      ) {
        return;
      }
      this.newOrgId = parseInt(orgId);
      this.setAuthorizeObject();
      await this.requestAuthorize(false);

      //   this.updateOrganization(orgId);
      //   this.$emit(
      //     "org-change",
      //     this.authorizeRtn.orgId,
      //     this.authorizeRtn.taxNo
      //   );
    },
    // 组织点选
    async handleTaxPayerSelect() {
      this.setAuthorizeObject();
      await this.requestAuthorize(false);
    },
    // 设置纳税主体
    setAuthorizeObject() {
      this.authorizeRtn = this.allTaxPayerList.find(
        (item) => item.id == this.newOrgId
      );
      if (this.authorizeRtn) {
        this.authorizeRtn.orgId = this.authorizeRtn.id;
      }
    },
    // 重新授权
    async handleReAuthorize() {
      await this.requestAuthorize(true);
    },
    // 请求授权
    async requestAuthorize(isDialog = true) {
      if (!this.authorizeRtn) {
        return;
      }

      localStorage.setItem("deductOrgId", this.authorizeRtn.orgId);

      // 检测设备在线状态
      const { success, data } = await reqState({
        orgId: this.authorizeRtn.orgId,
        taxNo: this.authorizeRtn.taxNo,
      });
      if (success) {
        this.authorizeRtn = { ...this.authorizeRtn, ...data };
        this.authorizeAfter(this.authorizeRtn);

        this.equipment = data;
        if (data.isReadied && !isDialog) {
          return;
        }
        if (data.authorizeMode == "RPA") {
          //   this.$store.state.deductAuthorize = this.authorizeRtn;
          //   if (data.isReadied) {
          //     this.sendSuccess = "3";
          //     // this.authorizeRtn.isReadied = true;
          //     // this.$emit("authorized", this.authorizeRtn);
          //   } else {
          //     // this.equipment.usernameForRpa = "";
          //     this.sendSuccess = "1";
          //   }
          this.loginRxDialogVisible = true;
        } else {
          this.loginSkDialogVisible = true;
        }
      }
    },
    authorizeAfter(org) {
      this.$store.state.deductAuthorize = org;
      // console.log("org-change: ", org);
      this.$emit("org-change", org);
    },
    // // 清除授权缓存
    // async handleClearCache() {
    //   let oldOrg = this.$store.state.user.organizations.find(
    //     (i) => i.id == this.oldOrgId
    //   );
    //   const { success } = await clearCacheState(oldOrg.taxNo);
    //   console.log(
    //     "清除授权缓存，税号：",
    //     oldOrg.taxNo,
    //     "，是否成功：",
    //     success
    //   );
    // },
    // // 切换组织信息
    // updateOrganization(orgId) {
    //   // 保留上次选择的组织ID
    //   this.oldOrgId = orgId;
    //   localStorage.setItem("deductOrgId", orgId);
    //   this.authorizeRtn = this.$store.state.user.organizations.find(
    //     (i) => i.id == orgId
    //   );
    //   if (this.authorizeRtn) {
    //     this.authorizeRtn.orgId = this.authorizeRtn.id;
    //   }
    // },
    // // 重置授权结果
    // resetAuthorized() {
    //   this.handleClearCache();
    //   this.authorizeRtn = {
    //     orgId: this.authorizeRtn.orgId,
    //     taxNo: this.authorizeRtn.taxNo,
    //   };
    // },
    async verificationCodeNotarize(data) {
      let res = await reqToken({
        taxNo: data.taxNo,
        isForce: true,
        orgId: data.orgId,
        usernameForRpa: data.usernameForRpa,
        passwordForRpa: data.passwordForRpa,
        smsVerifyCode: data.smsVerifyCode,
      });
      if (res.success) {
        this.sendSuccess = "3";
        this.authorizeRtn.isReadied = true;
        this.authorizeAfter(this.authorizeRtn);
      }
    },
    async getVerificationCode(data) {
      let res = await getSmsCode({
        taxNo: data.taxNo,
        isForce: true,
        usernameForRpa: data.usernameForRpa,
        passwordForRpa: data.passwordForRpa,
        id: data.orgId,
      });
      if (res.success) {
        this.sendSuccess = "2";
      }
    },
    // 授权登录
    async handleAuthorized(isForce = false, org) {
      if (org != undefined) {
        this.authorizeRtn = org;
      }

      const { success, data, message } = await reqToken({
        taxNo: this.authorizeRtn.taxNo,
        isForce: isForce,
      });
      if (!success) {
        this.toast(message, "error");
        return;
      }
      this.authorizeRtn = { ...this.authorizeRtn, ...data };
      this.$store.state.deductAuthorize = this.authorizeRtn;
      this.authorizeAfter(this.authorizeRtn);
    },
    // 格式化所属税期
    fmtTaxPeriod(val) {
      if (val) {
        let arr = val.split("");
        arr.splice(6, 0, "月");
        arr.splice(4, 0, "年");
        return arr.join("");
      } else {
        return "--";
      }
    },
    fmtDeadline(val) {
      if (val) {
        let arr = val.split("");
        arr.splice(6, 0, ".");
        arr.splice(4, 0, ".");
        return arr.join("");
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.authorized {
  display: flex;
  padding: 12px 24px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;

  .header-left {
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    label {
      width: 82px;
      color: #666666;
      font-size: 16px;
      padding-right: 12px;
    }

    span {
      color: #1890ff;
    }

    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    img {
      width: 20px;
      height: 20px;
      padding-right: 4px;
      vertical-align: bottom;
    }
  }

  .header-right {
    color: #666666;
    font-size: 14px;

    .header-period {
      color: #f5222d;
    }

    span {
      padding: 0 4px;
    }
  }
}

.org-select {
  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
  }

  ::v-deep .el-input__icon {
    color: #1890ff;
  }
}
</style>
