<template>
  <div>
    <div v-if="isReadied" class="content" @click="handleSync">
      <img src="@/assets/icon/deduction/synchro.png" alt="">
      <span>同步数据</span>
    </div>
    <el-tooltip v-else class="content" content="请保持认证设备在线">
      <div>
        <img src="@/assets/icon/deduction/synchro-faild.png" alt="">
        <span style="color:#999999">同步数据</span>
      </div>
    </el-tooltip>
    <p-dialog :visible.sync="visibled"
              button="同步"
              width="450px"
              @confirm="handleConfirm"
              @close="handleClose"
              :disabled="syncBtnDisabled"
    >
      <span slot="title">同步数据</span>
      <div slot="content">
        <el-form :model="invoice" :rules="rules" label-width="100px"
                 ref="singleInvoiceForm">
          <div class="item">
            <el-checkbox v-model="without">
              <span class="required">未抵扣发票</span>
              <el-tooltip placement="top">
                <div slot="content" v-html="notDeductedTips"></div>
                <i class="el-icon-question icon"></i>
              </el-tooltip>
            </el-checkbox>
            <el-form-item label="开票时间" prop="withoutDate" :rules="[{required: true,validator: validateWithOutDate,trigger: 'blur'}]">
              <el-date-picker
                  class="date-picker"
                  v-model="withoutDate"
                  :disabled="!without"
                  :picker-options="dateOption"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @input="withoutDateUpdate">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item">
            <el-checkbox v-model="already">
              <span class="required">已抵扣发票</span>
              <el-tooltip placement="top">
                <div slot="content" v-html="deductedTips"></div>
                <i class="el-icon-question icon"></i>
              </el-tooltip>
            </el-checkbox>
            <el-form-item label="所属税期" prop="alreadyMonth" :rules="[{required: true,validator: validateAlreadyMonth,trigger: 'blur'}]">
              <el-date-picker
                  class="date-picker"
                  v-model="alreadyMonth"
                  :disabled="!already"
                  :picker-options="alreadyOption"
                  value-format="yyyyMM"
                  type="month"
                  placeholder="选择月"
                  @input="alreadyMonthUpdate">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item">
            <el-checkbox v-model="statistics">
              <span class="required">抵扣统计表</span>
              <el-tooltip placement="top">
                <div slot="content" v-html="tableTips"></div>
                <i class="el-icon-question icon"></i>
              </el-tooltip>
            </el-checkbox>
            <el-form-item label="所属税期" prop="statisticsMonth" :rules="[{required: true,validator: validateStatisticsMonth,trigger: 'blur'}]">
              <el-date-picker
                  class="date-picker"
                  v-model="statisticsMonth"
                  :disabled="!statistics"
                  :picker-options="statisticsOption"
                  value-format="yyyyMM"
                  type="month"
                  placeholder="选择月"
                  @input="statisticsMonthUpdate">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="item">
              <el-checkbox v-model="singleInvoice">
                <span class="required">单张发票</span>
                <el-tooltip placement="top">
                  <div slot="content" v-html="singleTips"></div>
                  <i class="el-icon-question icon"></i>
                </el-tooltip>
              </el-checkbox>
              <el-form-item label="发票代码" prop="invoiceCode" class="singleInvoiceForm">
                <el-input v-model="invoice.invoiceCode" :disabled="!singleInvoice" maxlength="12"/>
              </el-form-item>
              <el-form-item label="发票号码" prop="invoiceNo" class="singleInvoiceForm">
                <el-input v-model="invoice.invoiceNo" :disabled="!singleInvoice" maxlength="8"/>
              </el-form-item>
          </div>
        </el-form>
      </div>
    </p-dialog>
  </div>
</template>

<script>
import PDialog from '@/components/pdt/PDialog'
import { dateOption } from '@/util/mixins'
import { syncData } from '@/service/deduction'
import RegexLib from '@/assets/js/regex-lib'

export default {
  name: 'SyncData',
  components: { PDialog },
  mixins: [dateOption],
  props: ['current', 'isReadied'],
  data () {
    return {
      notDeductedTips: "从税局同步当前组织在此时间段内所有的<br>未抵扣、未勾选的进项发票。",
      deductedTips: "从税局同步当前组织在此时间段内所有的<br>已抵扣的发票数据。",
      tableTips: "从税局同步历史抵扣统计表数据。",
      singleTips: "从税局同步单张发票的抵扣及勾选状态。",
      alreadyOption: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      statisticsOption: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      visibled: false,
      without: false,
      withoutDate: null,     // 未抵扣发票
      already: false,
      alreadyMonth: null,    // 已抵扣发票
      statistics: false,
      statisticsMonth: null,// 抵扣统计表
      invoiceTimeEnd: null,
      singleInvoice: false,
      invoice: { invoiceCode: '', invoiceNo: '' },
      validateStatus: false,
      rules: {
        invoiceCode: [
          { required: true, message: '请输入发票代码', trigger: 'blur' },
          { min: 10, max: 12, message: '请输入12位的发票代码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceCode, message: '请输入正确的发票代码' }
        ],
        invoiceNo: [
          { required: true, message: '请输入发票号码', trigger: 'blur' },
          { min: 8, max: 8, message: '请输入8位发票号码', trigger: 'blur' },
          { pattern: RegexLib.InvoiceNo, message: '请输入正确的发票号码' }
        ]
        // withoutDate: [
        //   { required: true, message: '请输入未抵扣发票的开票时间!', trigger: 'blur' },
        // ],
        // alreadyMonth: [
        //   { required: true, message: '请输入已抵扣发票的所属税期!', trigger: 'blur' }
        // ],
        // statisticsMonth: [
        //   { required: true, message: '请输入抵扣统计表的所属税期!', trigger: 'blur' }
        // ]
      }
    }
  },
  computed: {
    syncBtnDisabled () {
      let i = 0
      if (this.without) i++
      if (this.withoutDate?.length > 0) i += 4
      if (this.already) i++
      if (this.alreadyMonth) i += 4
      if (this.statistics) i++
      if (this.statisticsMonth) i += 4

      if (this.singleInvoice) i++
      //单张发票代码和号码校验成功才能同步，校验状态是通过validateStatus来判断
      if (this.validateStatus) i += 4

      return !(i > 4 && i % 5 == 0)
    }
  },
  watch: {
    without () {
      this.withoutDate = this.without ? this.withoutDate : null
      if(this.$refs.singleInvoiceForm){
        this.$refs.singleInvoiceForm.clearValidate('withoutDate')
      }
    },
    already () {
      this.alreadyMonth = this.already ? this.alreadyMonth : null
      this.$refs.singleInvoiceForm.clearValidate('alreadyMonth')
    },
    statistics () {
      this.statisticsMonth = this.statistics ? this.statisticsMonth : null
      this.$refs.singleInvoiceForm.clearValidate('statisticsMonth')
    },
    singleInvoice () {
      this.$refs.singleInvoiceForm.clearValidate(['invoiceCode','invoiceNo'])
    },
    invoice: {
      deep: true, //深度监听,invoice变化时要校验表单，把校验成功与否的信息返回
      handler: function () {
        let invForm = this.$refs.singleInvoiceForm
        if (!invForm) {
          this.validateStatus = false
          return
        }
        // 校验部分字段
        let fieldsToValidate = ['invoiceCode','invoiceNo'];
        Promise.all(
            fieldsToValidate.map((field) => {
              return new Promise((resolve, reject) => {
                invForm.validateField(field, (errorMessage) => {
                  resolve(errorMessage);
                });
              });
            })
        ).then((errorMessages) => {
          let valid = errorMessages.every((errorMessage) => {
            return errorMessage == '';
          });
          if (valid) {
            this.validateStatus = true
          } else {
            this.validateStatus = false
          }
        });
      }
    }
  },
  methods: {
    validateWithOutDate (rule , value, callback) {
      if(this.withoutDate=='' || this.withoutDate==undefined || this.withoutDate==null){
        callback(new Error('请输入未抵扣发票的开票时间!'));
      } else {
        callback();
      }
    },
    validateAlreadyMonth (rule , value, callback) {
      if(this.alreadyMonth=='' || this.alreadyMonth==undefined || this.alreadyMonth==null){
        callback(new Error('请输入已抵扣发票的所属税期!'));
      } else {
        callback();
      }
    },
    validateStatisticsMonth (rule , value, callback) {
      if(this.statisticsMonth=='' || this.statisticsMonth==undefined || this.statisticsMonth==null){
        callback(new Error('请输入抵扣统计表的所属税期!'));
      } else {
        callback();
      }
    },
    withoutDateUpdate () {
      this.$nextTick(() => {
        if(this.withoutDate=='' || this.withoutDate==undefined || this.withoutDate==null){
          this.$refs.singleInvoiceForm.clearValidate('withoutDate')
        }
      });
    },
    alreadyMonthUpdate () {
      this.$nextTick(() => {
        if(this.alreadyMonth=='' || this.alreadyMonth==undefined || this.alreadyMonth==null){
          this.$refs.singleInvoiceForm.clearValidate('alreadyMonth')
        }
      });
    },
    statisticsMonthUpdate () {
      this.$nextTick(() => {
        if(this.statisticsMonth=='' || this.statisticsMonth==undefined || this.statisticsMonth==null){
          this.$refs.singleInvoiceForm.clearValidate('statisticsMonth')
        }
      });
    },
    // 同步数据点击
    handleSync () {
      this.initDate()
      this.current ? this[this.current] = true : false
      this.visibled = true
    },
    // 初始化表格
    initDate () {
      this.without = false
      this.withoutDate = null
      this.already = false
      this.alreadyMonth = null
      this.statistics = false
      this.statisticsMonth = null
      this.singleInvoice = false
    },
    // 同步数据
    async handleConfirm () {
      let param = {}
      if (this.without && this.withoutDate) param['without'] = JSON.stringify(this.withoutDate)
      if (this.already && this.alreadyMonth) param['alreadyMonth'] = this.alreadyMonth
      if (this.statistics && this.statisticsMonth) param['statisticMonth'] = this.statisticsMonth
      if (this.singleInvoice && this.invoice) {
        param['invoiceCode'] = this.invoice.invoiceCode
        param['invoiceNo'] = this.invoice.invoiceNo
      }

      const { success } = await syncData({
        taxNo: this.$store.state.deductAuthorize.taxNo,
        ...param
      })
      if (success) this.toast('数据同步成功', 'success', () => this.$emit('sync-data'))

      this.invoice = { invoiceCode: '', invoiceNo: '' }
      //移除表单项校验规则
      this.$refs.singleInvoiceForm.clearValidate()
    },
    //表单取消时
    handleClose () {
      console.log('clearValidate。。。')
      this.$refs.singleInvoiceForm.clearValidate()
    }
  },
}
</script>

<style lang="scss" scoped>

img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  vertical-align: bottom;
}

.content {
  color: #1890ff;
  font-size: 16px;
  font-weight: 500;
}

.content:hover {
  cursor: pointer;
}

.item {
  padding: 8px 0;

  .date-picker {
    width: 280px;
  }

  .el-form-item {
    margin-top: 10px;
    padding-bottom: 16px;
    padding-left: 20px;
  }
}

.required:before {
  content: '*';
  color: #B60000;
}

.singleInvoiceForm {
  margin-top: 15px;
  margin-bottom: 15px;
}
.el-icon-question {
  color: #FF0000;
}
</style>
